import { rem } from 'polished';
import styled from 'styled-components';

import { breakpoints } from 'helpers/styles/constants';
import { container } from 'helpers/styles/mixins';

export const PageContents = styled.div`
  ${container()};
  padding-top: ${rem(50)};
  padding-bottom: ${rem(20)};

  @media ${breakpoints.smUp} {
    padding-top: ${rem(150)};
    padding-bottom: ${rem(200)};
  }
`;

export const Grid = styled.div`
  display: flex;
  flex-direction: column;

  @media ${breakpoints.smUp} {
    flex-direction: row;
  }
`;

export const Column = styled.div`
  width: 100%;

  &:first-child {
    padding-bottom: ${rem(50)};
  }

  @media ${breakpoints.smUp} {
    width: 50%;

    & + & {
      padding-left: ${rem(50)};
      border-left: ${rem(1)} solid ${({ theme }) => theme.colors.panelBorder};
    }

    &:first-child {
      padding-right: ${rem(50)};
      padding-bottom: 0;
    }
  }
`;
