import React, { InputHTMLAttributes, useCallback, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { rem, rgba } from 'polished';
import styled from 'styled-components';

import { useIsCapsLockActive } from 'hooks/useIsCapsLockActive';
import { BasicTooltip, TooltipPlacement } from 'components/GenericTooltip';

const InputWrapper = styled.div`
  position: relative;
  width: 100%;
`;

const Line = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  display: block;
  width: 100%;
  height: 1px;
  background-color: ${({ theme }) => rgba(theme.colors.bermudaGray, 0.5)};
  transition: background-color 0.3s ease;
`;

const ToggleButton = styled.button`
  position: absolute;
  top: ${rem(4)};
  right: 0;
  font-size: ${({ theme }) => theme.fontSizes.small};
  font-weight: ${({ theme }) => theme.fontWeights.mainBold};
  outline: none;
  opacity: 0.6;
  transition: opacity 0.3s ease;

  &:hover {
    opacity: 1;
  }
`;

const StyledInput = styled.input`
  width: 100%;
  padding-bottom: ${rem(10)};
  line-height: 1.3;
  background-color: transparent;
  border: none;

  &::-ms-clear,
  &::-ms-reveal {
    display: none;
  }

  &:not(:disabled) {
    &[aria-invalid='true'] ~ ${Line} {
      background-color: ${({ theme }) => theme.colors.crail};
    }

    &:focus,
    &:hover {
      & ~ ${Line} {
        height: 2px;
        background-color: ${({ theme }) => theme.colors.accent};
      }
    }
  }
`;

type Props = InputHTMLAttributes<HTMLInputElement>;

export function AuthInput({ onFocus, onBlur, type, ...props }: Props) {
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const [hasFocus, setHasFocus] = useState(false);

  const togglePasswordVisibility = useCallback(() => {
    setIsPasswordVisible(prevType => !prevType);
  }, []);

  const handleFocus = useCallback(
    (event: React.FocusEvent<HTMLInputElement>) => {
      setHasFocus(true);

      if (onFocus) {
        onFocus(event);
      }
    },
    [onFocus]
  );

  const handleBlur = useCallback(
    (event: React.FocusEvent<HTMLInputElement>) => {
      setHasFocus(false);

      if (onBlur) {
        onBlur(event);
      }
    },
    [onBlur]
  );

  const isCapsLockActive = useIsCapsLockActive();

  return (
    <InputWrapper>
      <BasicTooltip
        tooltipMessage={<FormattedMessage id="registration.loginPage.capsLockEnabled" />}
        position={TooltipPlacement.Right}
        disabled={type !== 'password'}
        visible={hasFocus && isCapsLockActive}
        hasAccentTheme
      >
        <StyledInput
          {...props}
          onFocus={handleFocus}
          onBlur={handleBlur}
          type={type === 'password' && isPasswordVisible ? 'text' : type}
        />
      </BasicTooltip>

      {type === 'password' && !props.disabled && props.value && (
        <ToggleButton type="button" onClick={togglePasswordVisibility}>
          <FormattedMessage id={isPasswordVisible ? 'shared.hide' : 'shared.show'} />
        </ToggleButton>
      )}

      <Line />
    </InputWrapper>
  );
}
