import React from 'react';
import { FormattedMessage } from 'react-intl';
import { rem } from 'polished';
import styled from 'styled-components';

import GenericButton from 'components/GenericButton/GenericButton';

const StyledButton = styled(GenericButton)`
  width: auto;
  min-width: ${rem(250)};
  /* https://github.com/philipwalton/flexbugs#flexbug-2 IE11 bug */
  max-width: 100%;
  padding: ${rem(20)} ${rem(50)};
  line-height: 1.36;
  font-family: ${({ theme }) => theme.fontFamilies.sec};
  font-size: ${({ theme }) => theme.fontSizes.dbMedium};
  font-weight: ${({ theme }) => theme.fontWeights.mainBold};
`;

interface Props {
  text: string;
  isDisabled?: boolean;
  onClick?: () => void;
}

const LandingButton = ({ text, isDisabled = false, onClick }: Props) => (
  <StyledButton
    type="submit"
    onClick={onClick}
    disabled={isDisabled}
    isHigh
    data-test="sign-in-btn"
  >
    <FormattedMessage id={text} />
  </StyledButton>
);

export default LandingButton;
