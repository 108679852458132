import React, { useCallback, useState } from 'react';
import { Form, FormProps } from 'react-final-form';
import { FormattedMessage, useIntl } from 'react-intl';
import { useMatomo } from '@datapunt/matomo-tracker-react';
import axios from 'axios';
import { noop } from 'lodash';
import { rem } from 'polished';
import styled from 'styled-components';

import { users } from 'api';
import EntityPath from 'constants/entitiesPaths';
import { SESSION_INFO } from 'constants/session';
import { normalizeData } from 'helpers/normalizers';
import { getAndRemoveSessionStorageValue, removeSessionStorageValue } from 'helpers/sessionStorage';
import { goToUrl, isNotBlank, withPreventDefault } from 'helpers/utils';
import { getEmptyError, required } from 'helpers/validators';
import routes, { getDashboardRoute } from 'routes';
import { prefixRoute } from 'routes/utils';
import { User, UserRoleName } from 'types/entities/user';
import DashboardSecurityLabel from 'components/DashboardSecurityLabel/DashboardSecurityLabel';
import GenericButton from 'components/GenericButton/GenericButton';
import { GenericTitle, TitleMargin } from 'components/GenericTitle/GenericTitle';
import { STORAGE_NAME } from 'components/SignInNotification/constants';

import { AuthInputField } from '../AuthInputField/AuthInputField';
import LandingButton from '../LandingButton/LandingButton';

const Link = styled.a`
  display: block;
  margin-top: ${rem(40)};
  font-size: ${({ theme }) => theme.fontSizes.dbNormal};
  line-height: 1.9;
  opacity: 0.8;
  color: ${({ theme }) => theme.colors.fountainBlue};
`;

const Buttons = styled.div`
  display: flex;
  align-content: center;

  & > * + * {
    margin-left: ${rem(20)};
  }
`;

const Errors = styled.div`
  margin-bottom: ${rem(20)};
  font-size: ${({ theme }) => theme.fontSizes.dbSmall};
  line-height: 1.3;
  color: ${({ theme }) => theme.colors.redDanger};

  ul {
    margin-top: ${rem(16)};
    padding: ${rem(16)};
    border: 1px dashed ${({ theme }) => theme.colors.mischka};

    li + li {
      margin-top: ${rem(10)};
    }
  }
`;

interface SessionInfo {
  pathName: string;
  userEmail: string;
}

export interface SubmitValues {
  email: string;
  password: string;
  otp?: string;
}

export interface Props {
  successMessage?: string;
}

export function LoginForm({ successMessage }: Props) {
  const intl = useIntl();
  const { trackPageView, pushInstruction } = useMatomo();

  const [hasOtpEnabled, setHasOtpEnabled] = useState(false);

  const signIn = useCallback<FormProps<SubmitValues>['onSubmit']>(
    async values => {
      const { email, password, otp } = values;
      const rememberMe = true;

      try {
        const response = await users().signIn().POST({ email, password, otp, rememberMe });
        const { data, headers } = response;

        if (headers['x-otp'] === 'required') {
          setHasOtpEnabled(true);
          return;
        }

        const loggedInUser = normalizeData<User>(data, EntityPath.Users);

        pushInstruction('requireCookieConsent');
        pushInstruction('setUserId', loggedInUser.id);
        trackPageView({});

        if (loggedInUser.isFirstSignIn && loggedInUser.roleName === UserRoleName.Client) {
          // first sign in, go to welcome page
          return goToUrl(prefixRoute(UserRoleName.Client, routes.welcome.routerPath()));
        }

        removeSessionStorageValue(STORAGE_NAME);
        const lastSessionInfo = getAndRemoveSessionStorageValue<SessionInfo>(SESSION_INFO);
        const lastSessionUrl = lastSessionInfo?.pathName;

        if (lastSessionInfo?.userEmail === email && isNotBlank(lastSessionUrl)) {
          return goToUrl(lastSessionUrl);
        }

        const route = getDashboardRoute(loggedInUser.roleName);
        goToUrl(route);
      } catch (error) {
        if (axios.isAxiosError(error)) {
          const errorCode = error.response?.headers['error-code'];
          const errorMessage = `registration.loginPage.errors.${errorCode}`;

          if (errorCode === 'invalid_email_or_password') {
            return {
              email: getEmptyError(),
              password: intl.formatMessage({ id: errorMessage }),
            };
          }

          if (errorCode === 'locked_user') {
            return {
              email: getEmptyError(),
              password: intl.formatMessage(
                { id: errorMessage },
                {
                  lock_period: error.response?.headers['lock-period'],
                }
              ),
            };
          }

          if (errorCode === 'invalid_2fa_code') {
            return {
              otp: intl.formatMessage({ id: errorMessage }),
            };
          }
        }
      }
    },
    [intl, pushInstruction, trackPageView]
  );

  return (
    <Form<SubmitValues>
      onSubmit={signIn}
      render={({ handleSubmit, submitting, form, values, hasSubmitErrors }) => {
        const submitAction = submitting ? noop : handleSubmit;

        return (
          <form onSubmit={withPreventDefault(submitAction)}>
            {successMessage && (
              <GenericTitle as="h2" marginBottom={TitleMargin.Lg} isMedium>
                {successMessage}
              </GenericTitle>
            )}

            <GenericTitle as="h2" marginBottom={TitleMargin.Lg} isMedium>
              {hasOtpEnabled ? (
                <FormattedMessage id="registration.loginPage.otpTitle" />
              ) : (
                <FormattedMessage id="registration.loginPage.title" />
              )}
            </GenericTitle>

            {hasOtpEnabled ? (
              <>
                <AuthInputField
                  name="otp"
                  type="text"
                  text="registration.loginPage.otp"
                  disabled={submitting}
                  validate={required}
                />
              </>
            ) : (
              <>
                <AuthInputField
                  name="email"
                  type="email"
                  text="registration.loginPage.emailLabel"
                  disabled={submitting}
                  validate={required}
                  // successMessage means we're on non-default login page
                  // (e.g. new email confirmation page)
                  autoComplete={successMessage ? 'off' : undefined}
                />

                <AuthInputField
                  name="password"
                  type="password"
                  text="shared.fields.password"
                  disabled={submitting}
                  validate={required}
                />
              </>
            )}

            {hasSubmitErrors && (
              <Errors>
                <ul>
                  <FormattedMessage
                    id="registration.loginPage.passwordIssues.issue1"
                    tagName="li"
                  />

                  <FormattedMessage
                    id="registration.loginPage.passwordIssues.issue2"
                    tagName="li"
                  />
                </ul>
              </Errors>
            )}

            <Buttons>
              <LandingButton
                text="registration.loginPage.signInButtonLabel"
                isDisabled={submitting}
              />

              {hasOtpEnabled && (
                <GenericButton
                  onClick={() => {
                    const { otp, ...rest } = values;
                    form.reset(rest);
                    setHasOtpEnabled(false);
                  }}
                  isTransparent
                >
                  <FormattedMessage id="shared.cancel" />
                </GenericButton>
              )}
            </Buttons>

            <Link href={routes.users.password.new.path()}>
              <FormattedMessage id="registration.loginPage.forgotPassword" />
            </Link>

            <DashboardSecurityLabel />
          </form>
        );
      }}
    />
  );
}
