import React from 'react';
import { rem } from 'polished';
import styled from 'styled-components';

import { Field, Label } from 'components/Forms';
import type { Props as FieldProps } from 'components/Forms/Field';

import { AuthInput } from './AuthInput';

const Wrapper = styled.div`
  margin-bottom: ${rem(30)};
  width: 100%;
  max-width: ${rem(500)};
`;

interface Props {
  name: string;
  text: string;
  type: string;
  disabled?: boolean;
  autoComplete?: string;
  validate?: FieldProps<string>['validate'];
}

export function AuthInputField({ name, text, validate, ...inputProps }: Props) {
  return (
    <Wrapper>
      <Field<string>
        name={name}
        validate={validate}
        renderLabel={({ input, meta }) => (
          <Label text={text} elementId={input.id} isActive={meta.active} />
        )}
        renderControl={({ input, meta }) => (
          <AuthInput {...input} aria-invalid={meta.showError} {...inputProps} />
        )}
      />
    </Wrapper>
  );
}
