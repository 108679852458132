import { rem } from 'polished';
import styled from 'styled-components';

const StyledLink = styled.a`
  display: inline-block;
  padding: ${rem(20)} ${rem(50)};
  line-height: 1.36;
  font-size: ${({ theme }) => theme.fontSizes.dbMedium};
  font-weight: ${({ theme }) => theme.fontWeights.mainBold};
  font-family: ${({ theme }) => theme.fontFamilies.sec};
  border: ${rem(2)} solid ${({ theme }) => theme.colors.accent};
  color: ${({ theme }) => theme.colors.accent};
`;

export default StyledLink;
