import React from 'react';
import { FormattedMessage } from 'react-intl';
import { rem } from 'polished';
import styled from 'styled-components';

import routes from 'routes';
import { GenericTitle, TitleMargin } from 'components/GenericTitle/GenericTitle';

import StyledLink from '../../components/StyledLink/StyledLink';

const AdditionalInfo = styled.div`
  margin-bottom: ${rem(40)};
  font-size: ${({ theme }) => theme.fontSizes.dbNormal};
  line-height: 1.9;
`;

function BecomeClient() {
  return (
    <>
      <GenericTitle as="h2" marginBottom={TitleMargin.Lg} isMedium>
        <FormattedMessage id="nav.signUp" />
      </GenericTitle>

      <AdditionalInfo>
        <FormattedMessage id="landing.additionalInfoSignUp" />
      </AdditionalInfo>

      <StyledLink href={routes.signUp.path()}>
        <FormattedMessage id="nav.signUp" />
      </StyledLink>
    </>
  );
}

export default BecomeClient;
