import React, { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';

import { isEnabled, ServerSideFeature } from 'helpers/features';
import { wrapWithStore } from 'helpers/store';
import { showErrorToast } from 'helpers/toasts';
import { logOut } from 'helpers/users';
import { getPathName } from 'helpers/utils';
import useLoggedInUserInfo from 'hooks/useLoggedInUserInfo';
import { getDashboardRoute } from 'routes';
import { useGetPracticeSettings } from 'store/settings/selectors';
import { GenericTitle, TitleMargin } from 'components/GenericTitle/GenericTitle';
import LandingApp from 'LandingApp';

import LandingButton from '../components/LandingButton/LandingButton';
import { LoginForm } from '../components/LoginForm/LoginForm';
import StyledLink from '../components/StyledLink/StyledLink';
import { Column, Grid, PageContents } from '../styled';
import BecomeClient from './BecomeClient/BecomeClient';

interface Props {
  successMessage?: string;
  notificationMessage?: string;
}

function HomePage({ successMessage, notificationMessage }: Props) {
  useEffect(() => {
    if (notificationMessage) {
      showErrorToast(notificationMessage, { isTranslated: true });
    }
  }, [notificationMessage]);

  const { isLoggedInUser, loggedUserRoleName } = useLoggedInUserInfo();
  const { tradingName, onboardingDisabled } = useGetPracticeSettings();

  return (
    <LandingApp>
      <PageContents>
        <GenericTitle marginBottom={TitleMargin.Lg}>{tradingName}</GenericTitle>

        <Grid>
          {isLoggedInUser ? (
            <>
              <Column>
                <GenericTitle as="h2" marginBottom={TitleMargin.Lg} isMedium>
                  <FormattedMessage id="registration.logout.title" />
                </GenericTitle>

                <LandingButton
                  text="registration.logout.signOutButtonLabel"
                  onClick={() => logOut(getPathName())}
                />
              </Column>

              <Column>
                <GenericTitle as="h2" marginBottom={TitleMargin.Lg} isMedium>
                  <FormattedMessage id="landing.goToDashboard" />
                </GenericTitle>

                <StyledLink
                  href={loggedUserRoleName ? getDashboardRoute(loggedUserRoleName) : undefined}
                >
                  <FormattedMessage id="nav.dashboard" />
                </StyledLink>
              </Column>
            </>
          ) : (
            <>
              <Column>
                <LoginForm successMessage={successMessage} />
              </Column>

              {isEnabled(ServerSideFeature.BecomeClient) && !onboardingDisabled && (
                <Column>
                  <BecomeClient />
                </Column>
              )}
            </>
          )}
        </Grid>
      </PageContents>
    </LandingApp>
  );
}

export default wrapWithStore(HomePage);
