import { useEffect, useState } from 'react';

export function useIsCapsLockActive() {
  const [isActive, setIsActive] = useState(false);

  useEffect(() => {
    const wasCapsLockActivated = (event: KeyboardEvent) => {
      if (event.getModifierState && event.getModifierState('CapsLock')) {
        setIsActive(true);
      }
    };

    const wasCapsLockDeactivated = (event: KeyboardEvent) => {
      if (event.getModifierState && !event.getModifierState('CapsLock')) {
        setIsActive(false);
      }
    };

    document.addEventListener('keydown', wasCapsLockActivated);
    document.addEventListener('keyup', wasCapsLockDeactivated);

    return () => {
      document.removeEventListener('keydown', wasCapsLockActivated);
      document.removeEventListener('keyup', wasCapsLockDeactivated);
    };
  }, []);

  return isActive;
}
